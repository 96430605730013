/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import PropTypes from 'prop-types';

// UI lib component

// Local UI components
import PageHeader from '../PageHeader';

// Style
import './index.scss';
import PageFooter from '../PageFooter';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */
/**
 * PageLayout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
function PageLayout({ children }) {
  /* ********************************** HOOKS ********************************* */

  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  /* ******************************** RENDERING ******************************* */

  return (
    <div className="w-100 h-100 overflow-x-hidden">
      <PageHeader />
      <main>{children}</main>
      <PageFooter />
    </div>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageLayout;
