/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { Link } from 'gatsby';

import { Container } from 'react-grid-system';

// Style
import './index.scss';

// assets
import logo from '../../images/header/logo-small.svg';
import Image from '../Image';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function PageHeader() {
  /* ********************************** HOOKS ********************************* */

  // Localization
  // const { t } = useTranslation();

  /* ******************************** RENDERING ******************************* */
  return (
    <header className="page-header">
      <Container>
        <div className="flex flex-v-center flex-between py-30 header-content">
          <Link href="/">
            <img src={logo} alt="Logo Tynes" />
          </Link>
          <Link href="/" className="ob-contain flex flex-center h-100">
            <Image src="header/logo-fondation.png" alt="Logo fondation" />
          </Link>
        </div>
      </Container>
    </header>
  );
}

PageHeader.propTypes = {};

PageHeader.defaultProps = {};

export default PageHeader;
