/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { Link } from 'gatsby';

import { Container } from 'react-grid-system';
import { useTranslation } from 'react-i18next';

// Style
import './index.scss';

// assets
import logo from '../../images/header/logo-small.svg';
import logoFondation from '../../images/footer/logo-large-fondation.png';
import fbIcon from '../../images/footer/fb-icon.svg';
import linIcon from '../../images/footer/linkedin-tyness.svg';
import igIcon from '../../images/footer/ig-icon.svg';
import appleLogo from '../../images/homepage/appcta/apple-white.svg';
import androidLogo from '../../images/homepage/appcta/android-white.svg';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function PageFooter() {
  /* ********************************** HOOKS ********************************* */

  // Localization
  const { t } = useTranslation();

  const {
    storesCTA: { apple, android },
  } = t('footer', {
    returnObjects: true,
  });

  /* ******************************** RENDERING ******************************* */
  return (
    <footer className="page-footer">
      <Container>
        <div className="flex flex-between py-48 footer-content">
          <img src={logo} alt="Tynes Logo" className="tynes-logo" />
          <img
            src={logoFondation}
            alt="Tynes Logo"
            className="fondation-logo"
          />
          {/* <Image src="logo-large-fondation" alt="logo fondation" /> */}
        </div>

        <div className="flex flex-between w-100 py-48 flex-wrap flex-center-m">
          <div className="flex flex-center">
            <Link
              href="https://www.facebook.com/people/Tynes/61553928339576/?mibextid=ZbWKwL"
              target="_blank"
              className="flex flex-center"
            >
              <img src={fbIcon} alt="Facebook page" />
              <p className="f-xxl-16 text-white mb-0 ms-12">Facebook</p>
            </Link>
            <Link
              href="https://www.instagram.com/tynes__tn/?igshid=NzZlODBkYWE4Ng%3D%3D"
              target="_blank"
              className="flex flex-center ps-48"
            >
              <img src={igIcon} alt="Instagram page" />
              <p className="f-xxl-16 text-white mb-0 ms-12">Instagram</p>
            </Link>
            <Link
              href="https://www.linkedin.com/company/tynes-tn/"
              target="_blank"
              className="flex flex-center ps-48"
            >
              <img src={linIcon} alt="linkedin page" />
              <p className="f-xxl-16 text-white mb-0 ms-12">LinkedIn</p>
            </Link>
          </div>
          <div className="flex flex-center flex-wrap mt-30-m gap-30">
            <button
              type="button"
              className="flex flex-center bg-dark-blue px-20 py-10 br6 border-white"
            >
              <img src={appleLogo} alt="apple store" />
              <p className="mb-0 f-lg-11 ms-18 text-start text-white">
                {apple.text}
                <br />
                <span className="f-lg-16 bold">{apple.name}</span>
              </p>
            </button>
            <button
              type="button"
              className="flex flex-center bg-dark-blue px-20 py-10 br6 border-white m-0-m"
            >
              <img src={androidLogo} alt="apple store" />
              <p className="mb-0 f-lg-11 ms-18 text-start text-white">
                {android.text}
                <br />
                <span className="f-lg-16 bold">{android.name}</span>
              </p>
            </button>
          </div>
        </div>
      </Container>
    </footer>
  );
}

PageFooter.propTypes = {};

PageFooter.defaultProps = {};

export default PageFooter;
